export default function Index() {
  return (
    <ul>
      <li>
        <a href="/client">client</a>
      </li>
      <li>
        <a href="/loader">loader</a>
      </li>
      <li>
        <a href="/action">action</a>
      </li>
    </ul>
  );
}
